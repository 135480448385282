import Constants from "../config/Constants";
import ChannelType from "../enums/ChannelType";
import Packet from "../enums/Packet";
import UserRole from "../enums/UserRole";
import Channel from "../models/Channel";
import Config from "../models/Config";
import User from "../models/User";
import ChannelUtils from "../utils/ChannelUtils";
import { db } from "./MytChatDB";


export default class UserDBOps {

    static shared = new UserDBOps()

    private constructor() { }

    getUser = (uuid: string) => {
        return db.user.get(uuid)
    }

    updateUser = async (user: User, isFriend: boolean) => {
        const dbUser = await db.user.get(user.uuid)

        if (dbUser !== undefined && dbUser.timestamp > user.timestamp) {
            return
        }

        await db.user.put(user)

        if (user.uuid === Config.shared.myUUID()) {
            //ignore self channel
            return
        }

        const channelID = ChannelUtils.makeChannel(user.uuid, user.role)

        let channel = await db.channel.get(channelID)

        if (channel === undefined) {
            channel = new Channel()

            channel.uuid = channelID
            channel.type = ChannelType.P2P
            channel.otherUserId = user.uuid
            channel.isInviteActionPending = Packet.Values.FALSE

            if (Config.shared.isStudent() && user.role === UserRole.STUDENT && !isFriend) {
                channel.isInviteActionPending = Packet.Values.TRUE
            } else if (Config.shared.isTeacher() && user.role === UserRole.TEACHER && !isFriend) {
                channel.isInviteActionPending = Packet.Values.TRUE
            }
        }

        //This handles old packets when keys were unavailable
        channel.name = user.name || channel.name
        channel.avatar = user.avatar || channel.avatar
        channel.isFriend = isFriend

        if (isFriend) {
            channel.isInviteActionPending = Packet.Values.FALSE
        }

        await db.channel.put(channel)
    }

    updateUserProfile = async (
        uuid: string,
        name: string,
        avatar: string,
        role: UserRole,
        status: number,
        timestamp: number
    ) => {
        let dbUser = await db.user.get(uuid)

        if (dbUser === undefined) {
            dbUser = new User()
            dbUser.uuid = uuid;
            dbUser.role = role;
        }

        dbUser.name = name || dbUser.name;
        dbUser.avatar = avatar || dbUser.avatar;

        dbUser.timestamp = timestamp

        await db.user.put(dbUser)

        if (uuid === Config.shared.myUUID()) {
            Config.shared.setProfile(dbUser)
            return
        }

        const channelID = ChannelUtils.makeChannel(uuid, role)

        let channel = await db.channel.get(channelID)

        if (channel !== undefined &&
            channel.isFriend &&
            status !== Packet.Values.TRUE
        ) {

            if (channel.otherUserId === Constants.CareUser) {
                return
            }

            await this.partFriend(uuid, role, timestamp)
            return
        }

        if (channel === undefined) {
            channel = new Channel()

            channel.uuid = channelID
            channel.type = ChannelType.P2P
            channel.otherUserId = uuid
            channel.isInviteActionPending = Packet.Values.FALSE

            if (Config.shared.isStudent() && dbUser.role === UserRole.STUDENT && status === Packet.Values.FALSE) {
                channel.isInviteActionPending = Packet.Values.TRUE
            }
            else if (Config.shared.isTeacher() && dbUser.role === UserRole.TEACHER && status === Packet.Values.FALSE) {
                channel.isInviteActionPending = Packet.Values.TRUE
            }
        }

        channel.name = dbUser.name
        channel.avatar = dbUser.avatar
        channel.isFriend = status === Packet.Values.TRUE

        if (channel.isFriend) {
            channel.isInviteActionPending = Packet.Values.FALSE
        }

        await db.channel.put(channel)
    }

    markFriend = async (userId: string, userRole: UserRole, timestamp: number) => {
        const channelId = ChannelUtils.makeChannel(userId, userRole)
        await db.channel.update(channelId, { isFriend: true, unread: 0 })
    }

    partFriend = async (userId: string, userRole: UserRole, timestamp: number) => {
        const channelId = ChannelUtils.makeChannel(userId, userRole)
        await db.channel.update(channelId, { isFriend: false, unread: 0 })
    }
}
import moment from "moment";
import { asyncForEach, isOnChatPage } from "../../utils/general";
import { CallbackHandler } from "../callbacks/handler";
import Constants from "../config/Constants";
import GroupRole from "../enums/GroupRole";
import MessageState from "../enums/MessageState";
import MessageType from "../enums/MessageType";
import Packet from "../enums/Packet";
import PinType from "../enums/PinType";
import { LastMessage } from "../models/Channel";
import Config from "../models/Config";
import Message, { Pinner, Reference } from "../models/Message";
import { WireMessage } from "../network/MessageHandler";
import RawPacket from "../packets/RawPacket";
import { ReceiptItem } from "../packets/ReceiptItem";
import { db } from "./MytChatDB";
import MessageUtils from "../utils/MessageUtils";
import { logger } from "../../utils/Logger";
import ChatService from "../../external/ChatService";

enum UpdateChannelUnreadCallType {
    None, Snapshot, NewMessage
}
export default class MessageDBOps {

    static shared = new MessageDBOps()

    private constructor() { }

    getMessage = (id: string) => {
        return db.message.get(id)
    }

    addMessage = async (messageItem: Message) => {
        try {
            await db.message.put(messageItem)
        } catch (e) {
            logger.debug(e)
        }

        await this.updateChannelForMessage(messageItem, UpdateChannelUnreadCallType.NewMessage);

        CallbackHandler.shared.callOnMessageAdded(messageItem)
    }

    updateSnapshotMessages = async (parsed: Array<{
        message: Message,
        unread: number
    }>) => {
        const messages = parsed.map((x) => x.message)
        try {
            await db.message.bulkPut(messages)
        } catch (ex) {
            logger.debug("updateSnapshotMessages", ex)
        }

        await asyncForEach(parsed, async (item) => {
            await this.updateChannelForMessage(item.message, UpdateChannelUnreadCallType.Snapshot, item.unread)
        })
    }

    updateChannelForMessage = async (
        message: Message,
        caller: UpdateChannelUnreadCallType = UpdateChannelUnreadCallType.None,
        unread: number = 0
    ) => {
        const channel = await db.channel.get(message.channel)

        if (channel === undefined || channel.lastMessage.timeHandle > message.timeHandle) {
            return
        }

        const updates = {
            lastMessage: new LastMessage(),
            unread: channel!.unread
        }

        updates.lastMessage.id = message.id
        updates.lastMessage.state = message.state
        updates.lastMessage.timeHandle = message.timeHandle

        if (message.state === MessageState.SEND_REJECTED) {
            updates.lastMessage.text = message.errorText
        } else {
            updates.lastMessage.text = (!message.isDeleted && message.media.length > 0) ? "📷 Media shared" : message.text;
        }

        if (caller == UpdateChannelUnreadCallType.Snapshot) {
            updates.unread = unread
        }
        else if (
            caller == UpdateChannelUnreadCallType.NewMessage &&
            Config.shared.currentChannel() !== message.channel
        ) {

            const unreadStates = [MessageState.RECEIVED, MessageState.DELIVERY_SENT]

            updates.unread = await db.message.where({ channel: channel.uuid })
                .and((x) => unreadStates.includes(x.state))
                .count()
        }

        await db.channel.update(message.channel, updates)
    }

    updateMessageStateToRejected = async (
        channelId: string,
        msgId: string,
        errorText: string,
        errorDesc: string,
    ) => {
        const changes = {
            state: MessageState.SEND_REJECTED,
            errorText: errorText,
            errorDesc: errorDesc
        };

        await db.message.update(msgId, changes);

        const channel = await db.channel.get(channelId)
        if (channel === undefined) return

        if (channel.lastMessage.id === msgId) {
            channel.lastMessage.state = MessageState.SENT

            await db.channel.update(channelId, {
                lastMessage: channel.lastMessage
            })
        }
    }

    updateMessageState = async (msgId: string, state: MessageState) => {
        const changes = {
            state: state,
        };

        return db.message.update(msgId, changes);
    }

    updateMessageStateToSent = async (
        channelId: string,
        msgId: string,
        timestamp: number,
        timeHandle: number,
    ) => {
        const changes = {
            state: MessageState.SENT,
            timestamp: timestamp,
            timeHandle: timeHandle
        };

        await db.message.update(msgId, changes);

        const channel = await db.channel.get(channelId)
        if (channel === undefined) return

        if (channel.lastMessage.id === msgId) {
            if (channel.lastMessage.state === MessageState.TO_SEND) {
                channel.lastMessage.state = MessageState.SENT
            }

            channel.lastMessage.timeHandle = timeHandle

            await db.channel.update(channelId, {
                lastMessage: channel.lastMessage
            })
        }
    }

    maxMessageHandle = async (channelId: string, state: MessageState): Promise<number> => {
        const messages = await db.message
            .where({ channel: channelId, state: state })
            .reverse()
            .sortBy("timeHandle")

        if (messages.length > 0) {
            return messages[0].timeHandle
        }

        return 0
    }

    /**
     *  info -> max time recorded on server for which current user sent read, delivery receipt
    */

    private fetchLocalArchivedMessagesInRange = async (
        channelId: string,
        items: Message[]
    ): Promise<Message[]> => {
        if (items.length === 0) {
            return []
        }

        const messages = await db.message.where(["channel", "timeHandle"])
            .between(
                [channelId, items[0].timeHandle],
                [channelId, items[items.length - 1].timeHandle], true, true).toArray()

        logger.debug("time: handles: ", items[items.length - 1].timeHandle, items[0].timeHandle, messages.length)

        return messages
    }

    addArchiveMessages = async (
        channelId: string,
        messageType: MessageType,
        items: Array<Message>,
        nextQueryHandle: number,
        latest: boolean,
        info: { read: number, delivery: number }
    ): Promise<void> => {
        var d2 = moment()
        const channel = await db.channel.get(channelId)

        if (channel === undefined) return;

        //sent message, delivery received
        let lastTimeHandleDelivered = await this.maxMessageHandle(channelId, MessageState.DELIVERED)
        let lastTimeHandleRead = await this.maxMessageHandle(channelId, MessageState.READ)

        //received message, delivery sent
        let lastTimeHandleDeliverySent = await this.maxMessageHandle(channelId, MessageState.DELIVERY_SENT)
        let lastTimeHandleReadSent = await this.maxMessageHandle(channelId, MessageState.READ_SENT)

        let minValidTimeHandle = 0

        if (messageType == MessageType.GROUP) {
            const dbMember = channel.members.get(Config.shared.myUUID())
            if (dbMember && dbMember.grole !== GroupRole.NONE) {
                minValidTimeHandle = dbMember.timestamp * 1000
            } else {
                minValidTimeHandle = Date.now() * 1000
            }
        }

        lastTimeHandleDelivered = Math.max(lastTimeHandleDelivered, info.delivery, minValidTimeHandle)
        lastTimeHandleRead = Math.max(lastTimeHandleRead, info.read, minValidTimeHandle)

        lastTimeHandleDeliverySent = Math.max(lastTimeHandleDeliverySent, info.delivery, minValidTimeHandle)
        lastTimeHandleReadSent = Math.max(lastTimeHandleReadSent, info.read, minValidTimeHandle)

        logger.leaveBreadcrumb("time: archive:d2: timehandle calculation...", {
            delta: moment().diff(d2, 'ms')
        }, 'log')

        const dbMessages = await this.fetchLocalArchivedMessagesInRange(channelId, items)

        const dbMessageMap = new Map<string, Message>()
        dbMessages.forEach((x) => {
            dbMessageMap.set(x.id, x)
        })

        items = items.map((item) => {

            if (item.isDeleted) {
                return item;
            }

            if (MessageState.READ >= item.state) {
                //send by me
                if (lastTimeHandleRead >= item.timeHandle) {
                    item.state = MessageState.READ;
                }
                else if (item.state === MessageState.READ) {
                    lastTimeHandleRead = item.timeHandle
                    lastTimeHandleDelivered = Math.max(item.timeHandle, lastTimeHandleDelivered)
                }
                else if (lastTimeHandleDelivered >= item.timeHandle) {
                    item.state = MessageState.DELIVERED
                }
                else if (item.state === MessageState.DELIVERED) {
                    lastTimeHandleDelivered = item.timeHandle
                }
            } else {
                //received
                if (lastTimeHandleReadSent >= item.timeHandle) {
                    item.state = MessageState.READ_SENT
                }
                else if (item.state === MessageState.READ_SENT) {
                    lastTimeHandleReadSent = item.timeHandle
                    lastTimeHandleDeliverySent = Math.max(item.timeHandle, lastTimeHandleDeliverySent)
                }
                else if (lastTimeHandleDeliverySent >= item.timeHandle) {
                    item.state = MessageState.DELIVERY_SENT
                }
                else if (item.state === MessageState.DELIVERY_SENT) {
                    lastTimeHandleDeliverySent = item.timeHandle
                }
            }

            const dbMessage = dbMessageMap.get(item.id)

            if (dbMessage !== undefined) {
                item.isPrivatePinned = dbMessage.isPrivatePinned
                item.isPublicPinned = dbMessage.isPublicPinned
            }

            return item
        });

        logger.leaveBreadcrumb("time:archive:d2: state update...", {
            delta: moment().diff(d2, 'ms')
        }, 'log')

        await db.message.bulkPut(items)

        logger.leaveBreadcrumb("time:archive:d2: db update...", {
            delta: moment().diff(d2, 'ms')
        }, 'log')

        const updates: RawPacket = {
            lastMessage: channel.lastMessage,
            unread: channel.unread
        }

        if (channel.hasPreviousMessages) {
            updates.hasPreviousMessages = nextQueryHandle !== Packet.Values.UNKNOWN

            if (channel.firstMessageTimeHandle == Packet.Values.UNKNOWN || channel.firstMessageTimeHandle > nextQueryHandle) {
                updates.firstMessageTimeHandle = nextQueryHandle
            }
        }

        if (messageType === MessageType.GROUP) {
            const unreadStates = [MessageState.RECEIVED, MessageState.DELIVERY_SENT]

            updates.unread = await db.message.where({ channel: channel.uuid })
                .and((x) => unreadStates.includes(x.state) && x.timeHandle > lastTimeHandleReadSent)
                .count()
        }

        if (latest && items.length > 0) {
            if (items[0].timeHandle >= channel.lastMessage.timeHandle) {

                updates.lastMessage.text =
                    (!items[0].isDeleted && items[0].media.length > 0) ?
                        "📷 Media shared" : items[0].text;

                updates.lastMessage.id = items[0].id;
                updates.lastMessage.timeHandle = items[0].timeHandle
                updates.lastMessage.state = items[0].state
            }
            else {
                const search = items.find(x => x.timeHandle == channel!.lastMessage.timeHandle)

                if (search) {
                    updates.lastMessage.text = (
                        !search.isDeleted && search.media.length > 0)
                        ? "📷 Media shared" : search.text;

                    updates.lastMessage.state = search.state
                }
            }
        }

        await db.channel.update(channelId, updates);

        logger.leaveBreadcrumb("time:archive: db channel update...", {
            delta: moment().diff(d2, 'ms')
        }, 'log')
    }

    //PIN
    updateBulkPinnedState = async (channelId: string, parsedItems: WireMessage[], pinType: PinType) => {
        //handle positive
        await asyncForEach(parsedItems, async (item: WireMessage) => {
            const dbItem = await db.message.get(item.message.id)

            if (dbItem === undefined) {

                if (pinType == PinType.PUBLIC) {
                    item.message.isPublicPinned = true
                }

                if (pinType == PinType.PRIVATE) {
                    item.message.isPrivatePinned = true
                }

                await db.message.put(item.message);
            } else {

                const updates: RawPacket = {
                    isPublicPinned: dbItem.isPublicPinned,
                    isPrivatePinned: dbItem.isPrivatePinned,
                }

                if (pinType == PinType.PUBLIC) {
                    updates.isPublicPinned = true
                    updates.pinner = item.pinner
                }

                if (pinType == PinType.PRIVATE) {
                    updates.isPrivatePinned = true
                }

                await db.message.update(item.message.id, updates);
            }
        })

        //handle negative
        const pinned = new Map<string, boolean>();
        parsedItems.forEach((item) => pinned.set(item.message.id, true));

        const filter: RawPacket = { "channel": channelId }
        const disable: RawPacket = {};
        let compareKey: string = ""

        if (pinType == PinType.PUBLIC) {
            compareKey = 'isPublicPinned'
            disable.isPublicPinned = false
        }

        if (pinType == PinType.PRIVATE) {
            compareKey = 'isPrivatePinned'
            disable.isPrivatePinned = false
        }

        await db.message
            .where(filter)
            .filter((dbItem) => !pinned.has(dbItem.id) && dbItem[compareKey] === true)
            .modify(disable)
    }


    updateMessagePinnedState = async (
        id: string,
        pinType: PinType,
        pinned: boolean,
        pinner?: Pinner,
    ) => {
        const dbItemKey = pinType === PinType.PUBLIC ? "isPublicPinned" : "isPrivatePinned";

        const updates: RawPacket = {};
        updates[dbItemKey] = pinned;

        if (pinType === PinType.PUBLIC) {
            if (pinned && pinner) {
                updates.pinner = pinner
            } else {
                updates.pinner = new Pinner()
            }
        }

        await db.message.update(id, updates);
    }

    //Receipt

    saveReceipt = async (packet: ReceiptItem) => {
        if (!!packet.deleteId) {
            await this.saveDeleteReceipt(packet, packet.deleteId!);
        }
        else if (!!packet.deliveredId) {
            await this.saveDeliveredReceipts(packet);
        }
        else if (!!packet.readId) {
            await this.saveReadReceipts(packet);
        }
    }

    saveDeleteReceipt = async (packet: ReceiptItem, deleteId: string) => {
        const msgChanges = {
            isPrivatePinned: false,
            isPublicPinned: false,
            isDeleted: true,
            reference: new Reference(),
            text: Constants.MessageRemoveText,
            media: []
        }

        await db.message.update(deleteId, msgChanges)

        const item = await db.channel.get(packet.channel)

        if (item !== undefined && item.lastMessage.timeHandle === packet.timeHandle) {

            item.lastMessage.text = msgChanges.text
            item.lastMessage.state = MessageState.SENDER_DELETED

            const channelChanges = {
                lastMessage: item.lastMessage
            }

            await db.channel.update(item.uuid, channelChanges)
        }
    }

    saveDeliveredReceipts = async (packet: ReceiptItem) => {
        if (packet.from === Config.shared.myUUID()) {
            //this user sent out receipt or carbon received
            const changes = {
                state: MessageState.DELIVERY_SENT
            }

            await db.message
                .where({ channel: packet.channel, state: MessageState.RECEIVED })
                .filter((item) => packet.timeHandle >= item.timeHandle)
                .modify(changes)

        }
        else {

            const validStates = [MessageState.TO_SEND, MessageState.SENT];

            //delivery receipt received for my packets
            const changes = {
                state: MessageState.DELIVERED
            }

            await db.message
                .where({ channel: packet.channel })
                .filter((item) => packet.timeHandle >= item.timeHandle && validStates.includes(item.state))
                .modify(changes)

            const channel = await db.channel.get(packet.channel)
            if (channel !== undefined
                && packet.timeHandle >= channel.lastMessage.timeHandle
                && validStates.includes(channel.lastMessage.state)) {

                channel.lastMessage.state = MessageState.DELIVERED

                await db.channel.update(packet.channel, {
                    lastMessage: channel.lastMessage
                })
            }
        }
    }

    markChannelRead = async (channelId: string) => {
        await db.channel.update(channelId, {
            unread: 0
        });
    }

    latestGroupUnreads = async (channelId: string): Promise<Set<string>> => {
        const validStates = [MessageState.RECEIVED, MessageState.DELIVERY_SENT, MessageState.READ_TO_SEND]
        const timeHandleCutOff = moment().subtract(7, 'days').valueOf() * 1000

        const items = await db.message.where({ channel: channelId })
            .filter(x => validStates.includes(x.state) && x.timeHandle > timeHandleCutOff)
            .reverse()
            .sortBy("timeHandle")

        let unreads = new Set<string>()
        items.forEach(x => {
            if (!unreads.has(x.from) && unreads.size < 10) {
                unreads.add(x.from)
            }
        })

        return unreads
    }

    saveReadReceipts = async (packet: ReceiptItem) => {
        if (packet.from === Config.shared.myUUID()) {
            //this user sent out receipt or carbon received
            const changes = {
                state: MessageState.READ_SENT
            }

            const validStates = [MessageState.RECEIVED, MessageState.DELIVERY_SENT];

            await db.message
                .where({ channel: packet.channel })
                .filter((item) => packet.timeHandle >= item.timeHandle && validStates.includes(item.state))
                .modify(changes)

            if (packet.readId == Packet.Values.ALL) {
                await this.markChannelRead(packet.channel)
            }
        }
        else {
            //delivery receipt received for my packets
            const changes = {
                state: MessageState.READ
            }

            const validStates = [MessageState.SENT, MessageState.DELIVERED];

            await db.message
                .where({ channel: packet.channel })
                .filter((item) => packet.timeHandle >= item.timeHandle && validStates.includes(item.state))
                .modify(changes)

            const channel = await db.channel.get(packet.channel)

            if (channel !== undefined
                && packet.timeHandle >= channel.lastMessage.timeHandle
                && validStates.includes(channel.lastMessage.state)) {

                channel.lastMessage.state = MessageState.READ

                await db.channel.update(packet.channel, {
                    lastMessage: channel.lastMessage
                })
            }
        }
    }

    updateMediaLink = async (messageId: string, dataKey: string, url: string) => {
        const message = await db.message.get(messageId)

        if (message === undefined) return

        const updates = {
            media: message.media.map(x => {
                if (x.dataKey === dataKey) {
                    x.url = url
                    x.validUpto = MessageUtils.mediaLinkValidity()
                }

                return x
            })
        }

        logger.debug(`updateMediaLink : ${messageId}: ${dataKey}: `, updates)
        await db.message.update(messageId, updates)
    }

    removeMessages = async (channelId: string) => {
        await db.message.where({ channel: channelId }).delete()
    }
}
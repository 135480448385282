import MessageType from "../enums/MessageType";
import Packet from "../enums/Packet"
import PinType from "../enums/PinType";
import ResponseCode from "../enums/ResponseCode";
import QueuedPacket from "../packets/QueuedPacket"
import RawPacket from "../packets/RawPacket"
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient";
import Message, { Pinner } from "../models/Message";
import MessageHandler from "./MessageHandler";
import UserDBOps from "../database/UserDBOps";
import MessageDBOps from "../database/MessageDBOps";
import GroupDBOps from "../database/GroupDBOps";
import { logger } from "../../utils/Logger";
import moment from "moment";
import { asyncForEach } from "../../utils/general";

export default class PinHandler {

    static shared = new PinHandler()

    private latestPins = new Map<string, boolean>()

    private constructor() { }

    clear = () => {
        this.latestPins.clear()
    }

    removeChannel = async (channelId: string) => {
        this.latestPins.delete(channelId)
    }

    // pin messages
    fetchPinnedMessages = async (channelId: string, messageType: MessageType, pinType: PinType) => {
        if (this.latestPins.has(channelId)) {
            return
        }

        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.PIN
        pkt[Packet.Keys.SUB_TYPE] = Packet.Pin.Types.GET
        pkt[Packet.Common.CHANNEL] = channelId;
        pkt[Packet.Pin.Keys.PIN_TYPE] = pinType;
        pkt[Packet.Message.Keys.MSG_TYPE] = messageType;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    //add
    pinMessageByTeacher = (message: Message) => {
        return this.pinMessage(message, PinType.PUBLIC)
    };

    pinMessageByCare = (message: Message) => {
        return this.pinMessage(message, PinType.PRIVATE)
    };

    pinMessageByStudent = (message: Message) => {
        return this.pinMessage(message, PinType.PRIVATE)
    };

    private pinMessage = (message: Message, pinType: PinType) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.PIN
        pkt[Packet.Keys.SUB_TYPE] = Packet.Pin.Types.ADD
        pkt[Packet.Common.CHANNEL] = message.channel;
        pkt[Packet.Pin.Keys.PIN_TYPE] = pinType;
        pkt[Packet.Message.Keys.MSG_TYPE] = message.type;
        pkt[Packet.Message.Reference.ID] = message.id;
        pkt[Packet.Message.Keys.TIME_HANDLE] = message.timeHandle;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    //remove p2p
    unpinMessageByTeacher = (message: Message) => {
        return this.unpinMessage(message, PinType.PUBLIC)
    };

    unpinMessageByCare = (message: Message) => {
        return this.unpinMessage(message, PinType.PRIVATE)
    };

    unpinMessageByStudent = (message: Message) => {
        return this.unpinMessage(message, PinType.PRIVATE)
    };

    private unpinMessage = (message: Message, pinType: PinType) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.PIN
        pkt[Packet.Keys.SUB_TYPE] = Packet.Pin.Types.DEL
        pkt[Packet.Common.CHANNEL] = message.channel;
        pkt[Packet.Pin.Keys.PIN_TYPE] = pinType;
        pkt[Packet.Message.Keys.MSG_TYPE] = message.type;
        pkt[Packet.Message.Reference.ID] = message.id;
        pkt[Packet.Message.Keys.TIME_HANDLE] = message.timeHandle;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.Pin.Types.GET:
                {
                    const success = res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK;
                    if (!success) {
                        return queued?.resolve?.()
                    }

                    let d3 = moment()

                    if (queued && Packet.Common.CHANNEL in queued.packet) {
                        //fetching pinned message
                        const items: Array<RawPacket> = res[Packet.Message.Keys.MESSAGES];

                        const pinType = queued.packet[Packet.Pin.Keys.PIN_TYPE] as PinType
                        const parsedItems = items.map(MessageHandler.shared.convertWireToMessage);
                        // let messages = parsedItems.map((item) => item.message);
                        const channelId = queued.packet[Packet.Common.CHANNEL] as string;

                        const processed = new Map<string, boolean>()

                        await asyncForEach(parsedItems, async (item) => {
                            if (item.user && !processed.has(item.user.uuid)) {
                                processed.set(item.user.uuid, true)
                                await UserDBOps.shared.updateUser(item.user, item.isFriend)
                                return
                            }

                            if (item.group && !processed.has(`${item.group.channel}:${item.group.member.uuid}`)) {
                                processed.set(`${item.group.channel}:${item.group.member.uuid}`, true)
                                await GroupDBOps.shared.updateMember(item.group.channel, item.group.member)
                            }
                        })

                        logger.leaveBreadcrumb('pin insert ', {
                            delta: moment().diff(d3)
                        }, 'log')

                        await MessageDBOps.shared.updateBulkPinnedState(channelId, parsedItems, pinType)

                        logger.leaveBreadcrumb('pin update db ', {
                            delta: moment().diff(d3)
                        }, 'log')

                        this.latestPins.set(channelId, true)
                    }

                    queued?.resolve?.()
                }
                break;
            case Packet.Pin.Types.ADD:
                {
                    const success = res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK;
                    if (!success) {
                        return queued?.resolve?.()
                    }

                    const msgId = res[Packet.Message.Reference.ID] as string;
                    const pinType = res[Packet.Pin.Keys.PIN_TYPE] as PinType;

                    const pinner = new Pinner()

                    pinner.uuid = res[Packet.Common.USER];
                    pinner.timestamp = res[Packet.Common.TIMESTAMP];

                    const item: RawPacket = res[Packet.Pin.Keys.PROFILE];

                    pinner.name = item[Packet.Account.Keys.NAME];
                    pinner.avatar = item[Packet.Account.Keys.AVATAR];
                    pinner.role = item[Packet.Account.Keys.ROLE];

                    await MessageDBOps.shared.updateMessagePinnedState(msgId, pinType, true, pinner)
                    queued?.resolve?.()
                }
                break;
            case Packet.Pin.Types.DEL:
                {
                    const success = res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK;
                    if (!success) {
                        return queued?.resolve?.()
                    }

                    const msgId = res[Packet.Message.Reference.ID] as string;
                    const pinType = res[Packet.Pin.Keys.PIN_TYPE] as PinType;

                    await MessageDBOps.shared.updateMessagePinnedState(msgId, pinType, false)
                    queued?.resolve?.()
                }
                break;
            default:
                {
                    logger.leaveBreadcrumb("PinHandler: unrecognized", {
                        type: res
                    })
                    logger.error("PinHandler: unrecognized");
                }
                break;
        }
    };
}
import { getAuthUserInfoOrDefault } from "../network/cookie";
import * as Sentry from "@sentry/react";
import UserRole from '../chat/enums/UserRole';

let authInfo = getAuthUserInfoOrDefault()

const shouldEnableSentry = () => {
    if (import.meta.env.VITE_APP_STAGE !== "production"
        || import.meta.env.SSR
        || window.location.hostname.includes("local")) {
        return false
    }

    return true
}

Sentry.init({
    dsn: "https://2aea00d59f10a80e6202601baa1f8cae@o4507713531936768.ingest.us.sentry.io/4507713738047488",
    integrations: [
        // Sentry.captureConsoleIntegration(),
    ],
    environment: import.meta.env.VITE_APP_STAGE,
    autoSessionTracking: true,
    enabled: shouldEnableSentry(),
    initialScope: (scope) => {

        if (authInfo.role === UserRole.UNKNOWN) {
            scope.setUser({ id: authInfo.uuid, role: authInfo.role })
        }

        return scope;
    },
});

export const createCustomLogger = (mustLog: boolean = false) => {

    const isEnabled = mustLog || (import.meta.env.VITE_APP_STAGE !== "production")

    return {
        setUser: (user: string) => {
            Sentry.setUser({ id: user })
        },
        leaveBreadcrumb: (message: string, metadata: { [key: string]: any } = {}, category: string = 'request') => {
            const data = metadata || {}
            data['internet'] = window?.navigator?.onLine || 'na'

            Sentry.addBreadcrumb({
                type: "default",
                level: "info",
                category: category,
                message: message,
                data: data,
                timestamp: Date.now() / 1000
            })

            if (isEnabled) {
                console.log(message, metadata)
            }
        },
        debug: (message: string, ...params: any[]) => {
            if (isEnabled) {
                console.log(message, ...params)
            }
        },
        error: (message: string | Error, metadata: { [key: string]: any } = {}) => {
            Sentry.captureException(message, {
                data: metadata
            })
            console.error(message)
        },
    }
}

export const logger = createCustomLogger()
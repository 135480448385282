import { getAuthUserInfoOrDefault } from "../../network/cookie";
import { isServerSideRendered } from "../../utils/general";

export default class Constants {

    //library version tagged to server release
    static readonly LIBRARY_VERSION = "3.2.1";

    static readonly MAX_LIMIT = 100;

    static readonly DEF_PROFILE_PIC = 'https://images.myyogateacher.com/profile-pic.png'
    static readonly DEF_GROUP_AVATAR = "https://res.cloudinary.com/dgerdfai4/image/upload/v1700811519/website/default-group-avatar.png"
    static readonly DEF_VIDEO_THUMB = "https://res.cloudinary.com/dgerdfai4/image/upload/v1687000008/Lv-landingpage/Video_2x.png"

    static readonly OneDayMillis = 86400 * 1000

    static readonly DISPLAY_RECENT_MEMBER = 5

    static readonly MessageRemoveText = "Message removed"

    static readonly PubSubChannelOpenTopic = "pubsub.channel.open"
    static readonly PubSubChannelCloseTopic = "pubsub.channel.close"
    static readonly PubSubMessageFailed = "pubsub.message.failed"
    static readonly PubSubArchiveResult = "pubsub.message.archive.result"
    static readonly PubSubOpenProfile = "pubsub.profile.open"
    static readonly PubSubNotiToggled = "pubsub.channel.notification.toggled"
    static readonly PubSubMediaShow = "pubsub.media.show"
    static readonly PubSubPinShow = "pubsub.channel.pin.show"
    static readonly PubSubLeaveGroup = "pubsub.group.leave"
    static readonly PubSubShareGroup = "pubsub.group.share"
    static readonly PubSubParticipantsShow = "pubsub.channel.participants.show"
    static readonly PubSubCreateGroupSuccess = "pubsub.group.create.success"
    static readonly PubSubMsiteMediaShow = "pubsub.media.msite.show"
    static readonly PubSubReplyOnMessageVisibility = "pubsub.message.reply.visibility"
    static readonly PubSubInviteIgnored = "pubsub.channel.invite.ignored"
    static readonly PubSubUnreadTotalCount = "pubsub.channels.unread.total"

    static readonly OnlineStatus = "Online"
    static readonly OfflineStatus = "Offline"

    static readonly CareUser = "care"

    static readonly BlockReasons = [
        "Inappropriate or offensive content",
        "Spam messages",
        "Off topic messages for this group",
        "Inaccurate or misleading Information",
        "Messages that violates privacy of other users",
        "All of the above"
    ]

    static readonly RemoveReasons = [
        "Inappropriate or offensive content",
        "Spam",
        "Off topic for this group",
        "Inaccurate or misleading Information",
        "Posted by mistake",
        "Violates privacy of other users",
        "All of the above"
    ]

    private static configKey = (key: string): string => {
        let confKey = `${key}-${import.meta.env.VITE_APP_STAGE}`

        if (isServerSideRendered()) {
            return confKey
        }

        return `${confKey}-${window.location.hostname}`
    }

    private static readonly ConfVersion = 'v8'
    static readonly DATABASE_NAME = Constants.configKey(`chat-db-${Constants.ConfVersion}`)

    static readonly SingletonUserConfigKey = Constants.configKey(`SingletonUserConfigKey-${Constants.ConfVersion}`)
    static readonly QueuedTasksKey = Constants.configKey('QueuedTasks')
    static readonly CachedMessagesKey = Constants.configKey('CachedMessagesKey')

    static readonly ARCHIVE_MESSAGE_PAGINATION_LIMIT = 500

    static readonly MENTION_ALL_UUID = "mention:all"
    static readonly DB_SOCKET_LOCK = Constants.configKey(`myyogateacher.exclusive.lock-${Constants.ConfVersion}`)
}
import GroupDBOps from "../database/GroupDBOps";
import MessageType from "../enums/MessageType";
import Packet from "../enums/Packet";
import Config from "../models/Config";
import ChatInitConfig from "../packets/ChatInitConfig";
import QueuedPacket from "../packets/QueuedPacket";
import RawPacket from "../packets/RawPacket";
import AccountHandler from "./AccountHandler";
import ChatClient from "./ChatClient";
import GroupHandler from "./GroupHandler";
import MessageHandler from "./MessageHandler";
import PingHandler from "./PingHandler";
import PushHandler from "./PushHandler";
import RelayHandler from "./RelayHandler";
import { v4 as uuidV4 } from 'uuid';
import { db } from "../database/MytChatDB";
import ResponseCode from "../enums/ResponseCode";
import S3Handler from "./S3Handler";
import { logger } from "../../utils/Logger";

type OnConnectionClosed = (result: { error: string, code: number }) => void

export default class InitHandler {

    static shared = new InitHandler()

    private onCloseCallback?: OnConnectionClosed = undefined

    private constructor() { }

    private config: ChatInitConfig = {
        global: {
            g_api: {
                version: "0.0.0",
                force_update: false
            },
            block_user_reasons: [],
            remove_message_reasons: []
        },
        user_specific: {
            u_api: {
                version: "0.0.0",
                force_update: false
            }
        },
        community_groups: new Array<{
            agent_id: string,
            channel_id: string,
            slug: string,
            thumbnail: string,
            title: string
        }>
    }


    logout = async () => {
        logger.debug("logout");
        await this.close(true);
        logger.debug("logout->conn->closed");
        S3Handler.shared.clear()
        await db.clear()
    }

    disconnect = async () => {
        await this.close(false)
    }

    private close = async (logout: boolean) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.DISCONNECT

        if (logout) {
            const token = Config.shared.cached().fcmPushToken
            if (token) {
                pkt[Packet.Keys.SUB_TYPE] = Packet.Disconnect.Types.LOGOUT
                pkt[Packet.Push.Keys.TOKEN] = token;
            }
        }

        pkt[Packet.Keys.ID] = uuidV4()

        if (!ChatClient.shared.sendPacket(pkt)) {
            return { error: "", code: ResponseCode.OK }
        } else {
            return new Promise((resolve, reject) => {
                this.onCloseCallback = resolve
            })
        }
    }

    onInitSyncDone = (isCareUser: boolean) => {
        if (Config.shared.cached().syncFcmPushToken) {
            PushHandler.shared.registerPush(Config.shared.cached().fcmPushToken)
        }

        if (isCareUser) {
            return
        }

        RelayHandler.shared.fetchRelayPackets()
        PushHandler.shared.getPushSetting()

        logger.leaveBreadcrumb("onInitSyncDone")
    }

    onDeInitPacketReceived = async (res: RawPacket) => {
        const err = res[Packet.Error.Keys.ERROR]
        const code = res[Packet.Keys.RESPONSE_CODE]

        this.onCloseCallback?.({ error: err, code: code })
        this.onCloseCallback = undefined
    }

    onInitPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        this.config = this.parseChatInitConfig(res)

        const isCareUser = Config.shared.isCare()

        PingHandler.shared.restartPingTimer()

        if (isCareUser) {
            AccountHandler.shared.fetchUserProfile(Config.shared.myUUID())
            this.onInitSyncDone(isCareUser)
            return
        }

        logger.leaveBreadcrumb("fetching user profile", {
            user: Config.shared.myUUID()
        }, 'request')

        AccountHandler.shared.fetchUserProfile(Config.shared.myUUID())

        GroupDBOps.shared.addCommunityGroups(this.config.community_groups).then((channelIds) => {
            logger.leaveBreadcrumb("community groups fetched, loading group data")
            GroupHandler.shared.fetchGroupByIds(channelIds)
        })

        await AccountHandler.shared.getFriends()
        logger.leaveBreadcrumb("friends fetched, fetching snapshot")
        MessageHandler.shared.getMessageSnapShot(MessageType.P2P)

        await GroupHandler.shared.fetchAllJoinedGroups()

        logger.leaveBreadcrumb("groups fetched, fetching snapshot")
        GroupHandler.shared.fetchInvitedGroups()
        MessageHandler.shared.getMessageSnapShot(MessageType.GROUP)

        Config.shared.setInitialSynced()

        this.onInitSyncDone(isCareUser)
    }

    parseChatInitConfig = (pkt: RawPacket): ChatInitConfig => {
        let gconf = pkt[Packet.Config.GLOBAL] as RawPacket;
        let lconf = pkt[Packet.Config.LOCAL] as RawPacket;

        let config: ChatInitConfig = {
            global: {
                g_api: {
                    version: gconf[Packet.Config.VERSION],
                    force_update: (gconf[Packet.Config.FORCED] == Packet.Values.TRUE)
                },
                block_user_reasons: gconf["block_user_reasons"] || ["All of the above"],
                remove_message_reasons: gconf["remove_message_reasons"] || ["All of the above"],
            },
            user_specific: false,
            community_groups: pkt[Packet.Config.LIST_COMMUNITY_GROUPS]
        };

        if (!!lconf) {
            config.user_specific = {
                u_api: {
                    version: lconf[Packet.Config.VERSION],
                    force_update: (lconf[Packet.Config.FORCED] == Packet.Values.TRUE)
                }
            };
        }

        return config;
    };
}
import InitHandler from '../chat/network/InitHandler';
import ChannelUtils from '../chat/utils/ChannelUtils';
import UserRole from '../chat/enums/UserRole';
import { isDesktop } from "react-device-detect"
import Constants from "../chat/config/Constants";
import { v4 as uuidv4 } from "uuid";
import ChatClient from "../chat/network/ChatClient";
import { useEffect, useState } from "react";
import { logger } from '../utils/Logger';
import PubSub from 'pubsub-js'
import { isOnChatPage } from '../utils/general';

const windowTarget = "myt-chat-window"

export enum QueuedType {
    sharePhotos = "share_photos"
}

export type QueuedData = {
    student_uuid: string,
    uuid: string
    signed_url: string
    filename: string
}

type QueuedCacheData = {
    pending_actions: Array<{
        uuid: string,
        type: QueuedType,
        data: QueuedData
    }>
}

const EventChatTabState = 'chat.window.tab.state'
const EventUnread = "chat.channels.total.unread"
const EventLogout = "chat.window.logout"

class ChatService {

    static shared = new ChatService()

    private windowRef: Window | null

    private broadcastChannel = new BroadcastChannel('com.myyogateacher.chat.channel');

    private lastUnreadState = 0;
    public isChatTabOpen = false

    private constructor() {
        this.broadcastChannel.onmessage = async (event: MessageEvent) => {
            event.preventDefault();

            switch (event.data.type) {
                case EventChatTabState:
                    console.log(`chattab-> ${event.data.opened}`)
                    this.isChatTabOpen = event.data.opened
                    break;
                case EventLogout:
                    await InitHandler.shared.logout()
                    ChatService.shared.windowRef?.close()
                    PubSub.publish(EventLogout, { event: 'logout' })
                    break;
                case EventUnread:
                    this.isChatTabOpen = true
                    this.updateUnreadCount(event.data.unread, true);
                    break
                default:
                    break;
            }
        }

        this.broadcastChannel.onmessageerror = (event: MessageEvent) => {
            logger.leaveBreadcrumb('ChatService.broadcastChannel.onmessageerror', {
                data: event
            })
        }
    }

    logout = async () => {
        await InitHandler.shared.logout()
        this.windowRef?.close()

        this.broadcastChannel.postMessage({
            type: EventChatTabState,
        })
    }

    openGroupChat = (
        uuid: string,
        newWindow: boolean = false,
    ) => {
        if (newWindow || isDesktop) {
            this.windowRef = window.open(`/chat?channel=${uuid}&type=grp`, windowTarget)
        } else {
            window.location.href = `/chat?channel=${uuid}&type=grp`
        }
    }

    openCareChat = (
        newWindow: boolean = false,
    ) => {
        const channelId = ChannelUtils.makeChannel(Constants.CareUser, UserRole.CARE)
        if (newWindow || isDesktop) {
            this.windowRef = window.open(`/chat?channel=${channelId}&type=p2p`, windowTarget)
        } else {
            window.location.href = `/chat?channel=${channelId}&type=p2p`
        }
    }

    openP2PChat = (
        user: string,
        role: UserRole = UserRole.UNKNOWN,
        newWindow: boolean = false
    ) => {
        const channelId = ChannelUtils.makeChannel(user, role)
        if (newWindow || isDesktop) {
            this.windowRef = window.open(`/chat?channel=${channelId}&type=p2p`, windowTarget)
        } else {
            window.location.href = `/chat?channel=${channelId}&type=p2p`
        }
    }

    openChatWindow = (
        newWindow: boolean = false
    ) => {
        if (newWindow || isDesktop) {
            this.windowRef = window.open(`/chat`, windowTarget)
        } else {
            window.location.href = `/chat`
        }
    }

    publishChatWindowState = (opened: boolean) => {
        logger.debug('publishChatWindowState', opened);

        this.broadcastChannel.postMessage({
            type: EventChatTabState,
            opened: opened
        })
    }

    updateUnreadCount = (updated: number, viaBroadcast: boolean = false) => {
        logger.debug('updateUnreadCount', updated);

        if (this.lastUnreadState === updated) {
            return
        }

        this.lastUnreadState = updated;

        if (viaBroadcast || !isOnChatPage()) {
            PubSub.publish(Constants.PubSubUnreadTotalCount, { unread: updated });
        } else {
            this.broadcastChannel.postMessage({
                type: EventUnread,
                unread: updated,
            })
        }
    }

    useTotalUnreadCount = () => {
        const [unread, setUnread] = useState(0)

        const setTotalUnreadCount = (topic: string, data: { unread: number }) => {
            setUnread(data.unread)
        }

        useEffect(() => {
            const unreadTotalCountToken = PubSub.subscribe(
                Constants.PubSubUnreadTotalCount,
                setTotalUnreadCount
            );

            return () => {
                PubSub.unsubscribe(unreadTotalCountToken);
            };
        }, [])

        return unread;
    }

    setQueuedTask = (type: QueuedType, data: QueuedData) => {
        const encoded = localStorage.getItem(Constants.QueuedTasksKey) || '{"pending_actions": []}'
        let decoded: QueuedCacheData = JSON.parse(encoded)
        decoded.pending_actions.push({
            uuid: uuidv4(),
            type: type,
            data: data
        })
        localStorage.setItem(Constants.QueuedTasksKey, JSON.stringify(decoded));
    }

    markQueuedTaskProcessed = (uuid: string) => {
        const encoded = localStorage.getItem(Constants.QueuedTasksKey) || '{"pending_actions": []}'
        let decoded: QueuedCacheData = JSON.parse(encoded)
        decoded.pending_actions = decoded.pending_actions.filter((x) => x.uuid !== uuid)
        localStorage.setItem(Constants.QueuedTasksKey, JSON.stringify(decoded));
    }

    getPendingQueuedTask = () => {
        const encoded = localStorage.getItem(Constants.QueuedTasksKey) || '{"pending_actions": []}'
        const decoded: QueuedCacheData = JSON.parse(encoded)
        return decoded.pending_actions[0]
    }

    initialize = () => {
        ChatClient.shared.initialize()
    }
}

export default ChatService.shared;
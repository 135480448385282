const Keys = {
    TYPE: "typ",
    SUB_TYPE: "st",
    ID: "id",
    RESPONSE_CODE: "rc",
    ACK: "ack"
};

const Types = {
    CONFIG: "myt:cfg",
    ACCOUNT: "myt:acnt",
    PING: "ping",
    PONG: "pong",
    DISCONNECT: "dcnt",
    ACK: "myt:ack",
    PUSH: "myt:push",
    PRESENCE: "myt:pres",
    MESSAGE: "myt:msg",
    PIN: "myt:pin",
    S3: "aws:s3",
    SMS: "myt:sms",
    RECO: "myt:reco",
    RELAY: "myt:ry",
    CARE: "myt:care",
    GROUP: "myt:grp",
    SUSPECT: "myt:sus"
}

const Config = {
    GLOBAL: "cfg:g",
    LOCAL: "cfg:l",
    VERSION: "ver",
    FORCED: "frcd",
    LIST_COMMUNITY_GROUPS: "lcg"
}

const Values = {
    UNKNOWN: -1,
    TRUE: 1,
    FALSE: 0,
    ALL: "all"
}

const Account = {
    Types: {
        PROFILE: "prof",
        FRIENDS: "cts",
        MAKE_FRIEND: "ct+",
        PART_FRIEND: "ct-",
        BLOCK: "blk+",
        UNBLOCK: "blk-",
        LIST_BLOCKED: "lblk",
        INVITE_ADD: "inv+",
        INVITE_REM: "inv-",
    },
    Keys: {
        NAME: "nm",
        AVATAR: "av",
        ROLE: "rl",
        SECONDS: "sec",
        LIST_CONTACTS: "lct",
        CONTACT_QUERY_HANDLE: "q:h",
        STATUS: "sts",
        TIMESTAMP: "ts", //seconds
    },
}

const Common = {
    USER: "u",
    USER_LIST: "ul",
    MORE: "mr",
    LIMIT: "lmt",
    FROM: "frm",
    TO: "to",
    CHANNEL: "chn",
    TIMESTAMP: "ts", // millis
    CARE: "care"
}

const Ack = {
    ID: "a:id"
}

const Push = {
    Types: {
        ADD_REG: "reg+",
        DEL_REG: "reg-",
        PUSH_SETTING_GET: "sg",
        PUSH_SETTING_SET: "ss"
    },
    Keys: {
        TOKEN: "tkn",
        TOKEN_TYPE: "tt",
        ListSetting: "lstg",
        PUSH: "psh",
        SOUND: "snd",
        PUSH_TYPE: "pt",
        TEXT: "txt",
        TIMESTAMP: "nts" // millis
    },
    Values: {
        ON: "on",
        OFF: "of"
    }
}

const Presence = {
    STATE: "ps",
    LAST_SEEN: "ls" // seconds
}

const Message = {
    Types: {
        CHAT: "chat",
        SNAPSHOT: "snpst",
        CHAT_STATE_TYPING: "cs:t",
        CHAT_STATE_CLEAR: "cs:c",
        ARCHIVE: "hm",
        RECEIPTS: "rcpts",
        NOTIFY: "noti",
        EVENT: "evt",
        RECEIPT_INFO: "rcpt:info",
        GROUP_ACTION: "ga"
    },
    Keys: {
        EVENT: "c:et",
        TIME_HANDLE: "t:h",
        MSG_TYPE: "m:t",
        MENTIONS: "lmu",
        PROFILE: "prof",
        PROFILE_RECEIVER: "prof:rcvr",
        MSG_STATE: "m:s",
        DATA: "data",
        REFERENCE: "rf",
        MESSAGES: "lm",
        RECEIPT: "rcpt",
        LIST_RECEIPTS: "lrcpt",
        ARCHIVE_RECEIPTS: "rcpts",
        FLAGS: "fg",
        PROFILE_CARE: "prof:care"
    },
    Flags: {
        FLAG_TYPE_CARE: "fg:cr",
        FLAG_TYPE_LAST_MESSAGE_TH: "lmth",
        FLAG_TYPE_AUTOMATED: "fg:at"
    },
    Reference: {
        ID: "rf:id",
        TYPE: "rt"
    },
    Location: {
        LATITUDE: "lt",
        LONGITUDE: "lg"
    },
    ARCHIVE: {
        DELIVERY: "ldr",
        READ: "lrr",
        EXPECTED_COUNT: "etc",
    },
    Receipt: {
        DELIVERY_ID: "dv:id",
        READ_ID: "rd:id",

        DELIVERY_HANDLE: "dv:h",
        READ_HANDLE: "rd:h",

        DELETE_ID: "dl:id",
        DELETE_REASON: "dl:rsn"
    },
    Media: {
        THUMB_KEY: "ftk",
        THUMB_URL: "ftu",
        DATA_KEY: "fdk",
        DATA_URL: "fdu",
        DURATION: "dur",
        SIZE: "fz",
        MIME: "mt",
        NAME: "fnm"
    },
    Data: {
        TEXT: "txt",
        LOCATION: "loc",
        CONTACT: "cntct",
        LINK: "link",
        MEDIA: "ml",
    },
    Mention: {
        START: "ms",
        END: "me"
    }
}

const Channel = {
    Type: "chnt"
}

const Error = {
    Keys: {
        ERROR: "err",
        DESCRIPTION: "dsc"
    }
}

const Recent = {
    Keys: {
        COUNT: "cnt"
    }
}

const Query = {
    LIMIT: "lmt",
    PAGE: "pg",
    ORDER: "ord",
    ORDER_PREV: "prv",
    ORDER_NEXT: "nxt",
    HANDLE: "q:h",
    MORE: "mr"
}

const Pin = {
    Types: {
        GET: "pin",
        ADD: "pin+",
        DEL: "pin-"
    },
    Keys: {
        USER: "pu",
        TIMESTAMP: "pts",
        PIN_TYPE: "pt",
        PROFILE: "pin:prof"
    }
}

const S3 = {
    Types: {
        SIGNED_URL: "url",
        SHARE_FILE: "shr"
    },
    Keys: {
        URL: "url",
        S3_PATH: "s3Path",
        FILE_KEY: "fkey",
        SHARED_KEY: "skey"
    }
}

const SMS = {
    Types: {
        LIST_GROUPS: "grps",
        ARCHIVE: "hm",
        CHAT: "chat"
    },
    Keys: {
        GROUPS: "lgrp",
    }
}

const Reco = {
    Types: {
        LIST_FREQUENTLY_CONTACTED: "lfq",
        LIST_YOU_MIGHT_KNOW: "lymk"
    },
    Keys: {
        HINT: "ht"
    }
}

const Relay = {
    Types: {
        GET: "lst",
        RECEIPTS: "rcpts",
        EVENT: "evt"
    },
    Keys: {
        READ_ID: "rd:id",
        RELAY_ID: "ry:id",
        FILTER_EVENT_TYPE: "fr:et",
        FILTER_SUB_TYPE: "fr:st",
        COUNT: "cnt",
        STATUS: "sts",
    },
    State: {
        DELETED: 0,
        VALID: 1,
        READ: 4
    }
}

const Care = {
    Types: {
        LIST_TICKETS: "ltkt",
        CLOSE_TICKET: "tkt-"
    }
}

const Group = {
    Types: {
        CREATE_GROUP: "grp+",
        DELETE: "grp-",
        LIST_MEMBERS: "mems",
        MEMBER_ADD: "mem+",
        MEMBER_REM: "mem-",
        LIST_GROUPS: "grps",
        NOTIFICATION: "noti",
        PROFILE: "prof",
        JOIN_LINK: "link",
        JOIN_LINK_INFO: "link:i",
        INVITE_ADD: "inv+",
        INVITE_REM: "inv-",
        INVITE_IGNORE: "inv:i",
        BLOCK: "blk+",
        UNBLOCK: "blk-",
        BLOCK_LIST: "lblk",
        RECEIPTS: "rcpts",
        MEMBERS_INVITED: "mems:i",
        INVITED_GROUPS: "grps:i"
    },
    Keys: {
        ROLE: "gr",
        NAME: "nm",
        AVATAR: "av",
        DESCRIPTION: "dsc",
        SGROUP_TYPE: "sgt",
        GROUPS: "lgrp",
        MEMBER: "gm",
        MEMBERS: "lgm",
        GROUP_TYPE: "gt",
        MEMBERS_CHANGE_TS: "mlct",
        BANNED: "bnd",
        TOKEN: "gtt",
        BLOCK_REASON: "blk:rsn",
        LINK: "link",
        IS_MEMBER: "ism",
        IS_ADMIN: "isa",
        IS_BLOCKED: "isb",
        REM_USER_PROF: "rem:u:prof",
        PARENT_USER_PROF: "prnt:u:prof",
        ADD_USER_PROF: "add:u:prof",
        GROUP_PROFILE: "grp:prof",
        USER_PROF: "u:prof",
        CREATE_MODE: "cm",
        COUNT: "cnt",
        INCLUDE_RECENT: "lrcnt",
        INVITE_TIMESTAMP: "i:ts"
    }
}

const Disconnect = {
    Types: {
        LOGOUT: "lgt"
    }
}

const Suspect = {
    Types: {
        GET: "get",
    },
    Keys: {
        ID: "sus:id",
        FLAGGED_BY_PROFILE: "flg:prof",
        SENT_BY_PROFILE: "snt:prof",
        REASON: "sus:rsn",
        TIMESTAMP: "sus:ts"
    }
}

const Packet = {
    Keys: Keys,
    Types: Types,
    Config: Config,
    Values: Values,
    Account: Account,
    Common: Common,
    Ack: Ack,
    Push: Push,
    Presence: Presence,
    Message: Message,
    Channel: Channel,
    Error: Error,
    Query: Query,
    Recent: Recent,
    Pin: Pin,
    S3: S3,
    SMS: SMS,
    Reco: Reco,
    Relay: Relay,
    Care: Care,
    Group: Group,
    Disconnect: Disconnect,
    Suspect: Suspect
}

export default Packet;